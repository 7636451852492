import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { IoMdCalendar, IoMdPin, IoMdLink } from 'react-icons/io'

const Container = styled.div`
  a,
  .label {
    color: ${({ theme }) => theme.colors.interactive};
    font-weight: 600;
  }

  .field {
    display: flex;
    margin-bottom: 0.8rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    .label {
      font-size: 1.4em;
      margin-right: 1.6rem;
    }

    .value {
      flex: 1;
      margin-top: 0.2rem;
    }
  }

  p.address {
    span {
      display: block;
      margin-top: 0.6rem;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .truncate {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;

    a {
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

const DialogBody = ({ event }) => (
  <Container>
    <div className='field'>
      <div className='label'>
        <IoMdPin />
      </div>
      <div className='value'>
        <p className='address'>
          {event.address &&
            event.address.split(',').map((line, index) => (
              <span key={line}>{`${line.trim()}${index === event.address.split(',').length - 1 ? '' : ','}`}</span>
            ))
          }
          {!event.address && event.location}
        </p>
      </div>
    </div>
    <div className='field'>
      <div className='label'>
        <IoMdCalendar />
      </div>
      <div className='value'>
        <p>{event.datetime}</p>
      </div>
    </div>
    <div className='field'>
      <div className='label'>
        <IoMdLink />
      </div>
      <div className='value truncate'>
        <a href={event.url} target='_blank' title={event.title}>{event.url}</a>
      </div>
    </div>
  </Container>
)

DialogBody.propTypes = {
  event: PropTypes.shape({
    datetime: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    address: PropTypes.string,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  })
}

export default DialogBody
