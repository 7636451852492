import React from 'react'
import Layout from '../components/Layout'
import ContentTitle from '../components/ContentTitle'
import EventsGlobe from '../components/EventsGlobe'
import styled from 'styled-components'

const GlobeContainer = styled.div`
  margin-top: 60px;
`

export default () => (
  <Layout pageContentPath='community.md'>
    <ContentTitle title='Community' />
    <GlobeContainer>
      <EventsGlobe loaderTopPosition='20%' />
    </GlobeContainer>
  </Layout>
)
