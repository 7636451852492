import React, { Component, createRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import TinyColor from '@ctrl/tinycolor'
import MediaQuery from 'react-responsive'
import ReactEventsGlobe from 'react-globe-events-visualiser'
import styled, { keyframes } from 'styled-components'
import Query from './Query'
import Button from '../Button'
import Modal from '../Modal'
import { ThemeConsumer } from '../../state'
import { makeTransparent } from '../../helpers/color'
import Loader from '../Loader'
import DialogTitle from './DialogTitle'
import DialogBody from './DialogBody'
import BackButton from './BackButton'
import CloseButton from './CloseButton'
import { getInnerWidth, getInnerHeight, addEventListener, removeEventListener } from '../../helpers/dom'
import config from '../../config'

const scaleIn = keyframes`
  0% {
    transform: scale(0.03, 0);
  }

  50% {
    transform: scale(0.03, 1);
  }

  100% {
    transform: scale(1, 1);
  }
`

const scaleOut = keyframes`
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(0.03, 1);
  }

  100% {
    transform: scale(0.03, 0);
  }
`

const Container = styled.div`
  width: 100%;
  text-align: center;
`

const GlobeContainer = styled.div`
  > div.mask {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;

    &.ready {
      opacity: 1;
    }
  }

  .dialog {
    border-radius: 1rem;
    text-align: left;
    letter-spacing: 0.1em;

    a {
      font-weight: 600;
    }

    .dialog-header,
    .dialog-body {
      transition: opacity 500ms ease-in-out;
    }

    .dialog-header {
      h3 {
        margin-top: 0.2rem;
      }
    }
  }

  .dialog-container,
  .dialog-container-leave {
    transform: scale(1);
  }

  .dialog-container-leave {
    .dialog-header,
    .dialog-body {
      opacity: 0;
      transition-duration: 500ms;
    }
  }

  .dialog-container-enter {
    transform: scale(0);

    .dialog-header,
    .dialog-body {
      opacity: 0;
    }
  }

  .dialog-container-enter-active {
    animation: ${scaleIn} 500ms ease-in-out;
    animation-iteration-count: 1;
  }

  .dialog-container-leave-active {
    animation: ${scaleOut} 600ms ease-in-out;
    animation-delay: 500ms;
    animation-iteration-count: 1;
  }
`

const LoaderContainer = styled.div`
  display: block;
  position: absolute;
  left: 50%;
  top: ${({ top }) => top};
  transform: translate(-50%, -50%);
`

const DEFAULT_WIDTH = 400
const DEFAULT_HEIGHT = 400
const HEIGHT_OFFSET = 40

class EventsGlobe extends Component {
  static propTypes = {
    loaderTopPosition: PropTypes.string
  }

  static defaultProps = {
    loaderTopPosition: '50%'
  }

  constructor (props) {
    super(props)
    this.containerRef = createRef()
    this.state = {
      width: DEFAULT_WIDTH,
      height: DEFAULT_HEIGHT,
      showModal: false,
      delayComplete: true,
      isReady: false
    }
  }

  onResize = () => {
    if (!this._isMounted) return
    let width, height
    const screenWidth = getInnerWidth()
    const screenHeight = getInnerHeight()
    if (screenWidth >= 800 && !this.containerRef.current) return
    if (screenWidth < 800) {
      width = screenWidth - 30
      height = screenHeight - 30
    } else {
      width = this.containerRef.current.offsetWidth
      height = (!screenHeight || screenHeight < DEFAULT_HEIGHT + HEIGHT_OFFSET)
        ? DEFAULT_HEIGHT - HEIGHT_OFFSET
        : screenHeight
    }

    this.setState({ width, height })
  }

  completeRenderDelay = () => {
    if (!this._isMounted) return
    this.setState({ delayComplete: true })
  }

  componentDidMount () {
    this._isMounted = true
    this.onResize()
    addEventListener('resize', this.onResize)
  }

  componentWillUnmount () {
    this._isMounted = false
    removeEventListener('resize', this.onResize)
  }

  openGlobeButtonClickHandler = (e) => {
    e.preventDefault()
    this.onResize()
    this.setState({ showModal: true, delayComplete: false, isReady: false })
    setTimeout(this.completeRenderDelay, 1000)
  }

  renderOpenGlobe (theme, enableZoom) {
    return (
      <Fragment>
        <Button onClick={this.openGlobeButtonClickHandler}>
          View Cardano events
        </Button>
        <Modal isVisible={this.state.showModal} onClose={() => this.setState({ showModal: false })}>
          {this.renderGlobe(theme, enableZoom)}
        </Modal>
      </Fragment>
    )
  }

  onReady = async () => {
    if (!this._isMounted) return
    await new Promise(resolve => setTimeout(resolve, 4000))
    if (!this._isMounted) return
    this.setState({ isReady: true })
  }

  renderGlobe (theme, enableZoom) {
    return (
      <Query
        render={events => (
          <GlobeContainer ref={this.containerRef}>
            <div className={`mask${this.state.isReady ? ' ready' : ''}`}>
              {this.state.delayComplete &&
                <ReactEventsGlobe
                  width={this.state.width}
                  height={this.state.height}
                  events={this.state.isReady ? events : []}
                  initZoomLevel={0.4}
                  globeTextureURL={theme.images.globe}
                  globeBumpTextureURL={null}
                  onReady={this.onReady}
                  enableZoom={enableZoom}
                  DialogTitleComponent={DialogTitle}
                  DialogBodyComponent={DialogBody}
                  markerFontSize={0.5}
                  theme={{
                    markerColor: parseInt(new TinyColor(theme.colors.interactive).toHex(), 16),
                    markerHighlightColor: parseInt(new TinyColor(theme.colors.interactiveHighlight).toHex(), 16),
                    markerFontColor: parseInt(new TinyColor(theme.colors.interactive).toHex(), 16),
                    markerFontHighlightColor: parseInt(new TinyColor(theme.colors.interactiveHighlight).toHex(), 16),
                    dialog: {
                      transitionName: 'dialog-container',
                      transitionEnterTimeout: 500,
                      transitionLeaveTimeout: 1000,
                      titleFontColor: theme.colors.text,
                      titleFontWeight: '400',
                      headerBackground: makeTransparent(theme.colors.primary, 0.8),
                      bodyBackground: 'transparent',
                      containerBackground: makeTransparent(theme.colors.primaryHighlight, 0.8),
                      shadowColor: 'transparent',
                      linkColor: theme.colors.interactive,
                      buttonColor: theme.colors.text,
                      bodyFontColor: theme.colors.text,
                      backButton: <BackButton />,
                      closeButton: <CloseButton />
                    }
                  }}
                />
              }
            </div>
            {!this.state.isReady &&
              <LoaderContainer top={this.props.loaderTopPosition}>
                <Loader />
              </LoaderContainer>
            }
          </GlobeContainer>
        )}
      />
    )
  }

  render () {
    return (
      <ThemeConsumer>
        {({ theme }) => (
          <Container>
            <MediaQuery query='(hover: none)'>
              {this.renderOpenGlobe(theme, true)}
            </MediaQuery>
            <MediaQuery query='(hover: hover)'>
              <MediaQuery query={`(max-width: ${config.mobileHeaderBreakPoint - 1}px)`}>
                {this.renderOpenGlobe(theme, false)}
              </MediaQuery>
              <MediaQuery query={`(min-width: ${config.mobileHeaderBreakPoint}px)`}>
                {this.renderGlobe(theme, false)}
              </MediaQuery>
            </MediaQuery>
          </Container>
        )}
      </ThemeConsumer>
    )
  }
}

export default EventsGlobe
