import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ThemeConsumer } from '../../state'

const Container = styled.div`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 30rem;
  text-align: center;
  position: relative;
`

const Title = styled.h2`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text};
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 1.2em;
  margin: 0;
  padding-bottom: 2.5rem;

  &:after {
    content: '';
    position: absolute;
    border-top: 0.1rem solid ${({ theme }) => theme.colors.secondary};
    width: 14rem;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -200%);
    opacity: 0.5;
  }
`

const DownArrow = styled.img`
  position: absolute;
  width: 2.8rem;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const ContentTitle = ({ title }) => (
  <ThemeConsumer>
    {({ theme }) => (
      <Container>
        <Title>{title}</Title>
        <DownArrow src={theme.images.titleDownArrow} />
      </Container>
    )}
  </ThemeConsumer>
)

ContentTitle.propTypes = {
  title: PropTypes.string.isRequired
}

export default ContentTitle
