import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import * as moment from 'moment'
import tzLookup from 'tz-lookup'

function getLocalTime (lat, lon, datetime) {
  return moment.tz(datetime, tzLookup(lat, lon))
}

function getDistanceBetweenPoints ({ lat: lat1, lon: lon1 }, { lat: lat2, lon: lon2 }) {
  const r = 12742 // Radius of Earth in km
  const deg = Math.PI / 180
  const a = 0.5 - Math.cos((lat2 - lat1) * deg) / 2 + Math.cos(lat1 * deg) * Math.cos(lat2 * deg) * (1 - Math.cos((lon2 - lon1) * deg)) / 2
  return r * Math.asin(Math.sqrt(a))
}

const GROUPED_DISTANCE = 100

function groupEvents (events) {
  const indexesSorted = []
  const groupedEvents = []
  events.forEach((e, i) => {
    if (indexesSorted.includes(i)) return
    indexesSorted.push(i)
    let group = [ e ]
    events.forEach((evt, index) => {
      if (indexesSorted.includes(index)) return
      const distance = getDistanceBetweenPoints(e, evt)
      if (distance <= GROUPED_DISTANCE) {
        group.push(evt)
        indexesSorted.push(index)
      }
    })

    groupedEvents.push(group)
  })

  return groupedEvents
}

const Query = ({ render }) => (
  <StaticQuery
    query={graphql`
      query{
        file(relativePath:{eq:"content/events.md"}) {
          childMarkdownRemark{
            frontmatter {
              events_list {
                geolocation
                name
                location
                when
                url
                address
              }
            }
          }
        }
      }
    `}
    render={({ file }) => {
      const events = file.childMarkdownRemark.frontmatter.events_list
      if (!events) throw new Error(`No events found`)
      return render(groupEvents(events.map(e => {
        const event = { ...e }
        const geolocation = JSON.parse(e.geolocation)
        event.lat = geolocation.coordinates[1]
        event.lon = geolocation.coordinates[0]
        const localTime = getLocalTime(event.lat, event.lon, event.when)
        // If event was in the past then don't display it
        if (localTime.diff(getLocalTime(event.lat, event.lon, moment()), 'seconds') < 0) return null
        return {
          ...event,
          id: `${event.lat}${event.lon}${event.name}`,
          datetime: localTime.format('LLLL z')
        }
      }).filter(e => e)))
    }}
  />
)

Query.propTypes = {
  render: PropTypes.func.isRequired
}

export default Query
