import React from 'react'
import { IoIosArrowDropleft } from 'react-icons/io'
import styled from 'styled-components'

const Container = styled.div`
  font-size: 1.5em;
  margin-right: 1.6rem;
  cursor: pointer;
`

export default () => (
  <Container>
    <IoIosArrowDropleft />
  </Container>
)
