import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import styled, { keyframes } from 'styled-components'
import { IoMdClose } from 'react-icons/io'
import TinyColor from '@ctrl/tinycolor'
import { getBody } from '../../helpers/dom'

const getModalBorderColor = theme => {
  const color = new TinyColor(theme.colors.primaryHighlight)
  color.setAlpha(0.5)
  return color.toRgbString()
}

const scaleIn = keyframes`
  0% {
    transform: scale(0.03, 0);
  }

  50% {
    transform: scale(0.03, 1);
  }

  100% {
    transform: scale(1, 1);
  }
`

const scaleOut = keyframes`
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(0.03, 1);
  }

  100% {
    transform: scale(0.03, 0);
  }
`

const ModalContainer = styled.div`
  .modal-content-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: ${({ theme }) => theme.colors.primary};
    border: 0 solid ${({ theme }) => getModalBorderColor(theme)};
    border-top-width: 1.5rem;
    border-bottom-width: 1.5rem;

    .modal-content-inner-wrapper {
      position: absolute;
      z-index: 1;
      top: -1.5rem;
      bottom: -1.5rem;
      width: 100%;
      border: 0 solid ${({ theme }) => getModalBorderColor(theme)};
      border-left-width: 1.5rem;
      border-right-width: 1.5rem;
    }
  }

  .modal-content {
    transition: opacity 500ms ease-in-out;
    opacity: 1;
  }

  .cardano-modal-leave {
    transform: scale(1);

    .modal-content {
      opacity: 0;
    }
  }

  .cardano-modal-enter {
    transform: scale(0);

    .modal-content {
      opacity: 0;
    }
  }

  .cardano-modal-enter-active {
    animation: ${scaleIn} 500ms ease-in-out;
    animation-iteration-count: 1;
  }

  .cardano-modal-leave-active {
    animation: ${scaleOut} 600ms ease-in-out;
    animation-delay: 500ms;
    animation-iteration-count: 1;
  }
`

const CloseContainer = styled.div`
  position: absolute;
  right: 0;
  z-index: 1;
  width: 15rem;
  height: 15rem;
  border-radius: 7.5rem;
  transform: translate(50%, -40%);
  background-color: ${({ theme }) => theme.colors.primaryHighlight};

  a {
    color: ${({ theme }) => theme.colors.text};
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 4rem;
    transform: translate(-100%, -10%);
  }
`

export default class Modal extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func
  }

  static defaultProps = {
    isVisible: false,
    onClose: () => {}
  }

  onClose = (e) => {
    e.preventDefault()
    this.props.onClose()
  }

  renderCloseComponent () {
    return (
      <CloseContainer>
        <a href='#' onClick={this.onClose}>
          <IoMdClose />
        </a>
      </CloseContainer>
    )
  }

  render () {
    return createPortal((
      <ModalContainer>
        <ReactCSSTransitionGroup
          transitionName='cardano-modal'
          transitionEnterTimeout={500}
          transitionLeaveTimeout={1000}
        >
          {this.props.isVisible &&
            <div className='modal-content-wrapper' key='modal-content-wrapper'>
              <div className='modal-content-inner-wrapper'>
                {this.renderCloseComponent()}
                <div className='modal-content'>
                  {this.props.children}
                </div>
              </div>
            </div>
          }
        </ReactCSSTransitionGroup>
      </ModalContainer>
    ), getBody())
  }
}
