import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.interactive};
  color: ${({ theme }) => theme.colors.buttonColor};
  border: none;
  border-radius: 0.2rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 0.8em;
  padding: 0.6rem 1.8rem;
  font-weight: 600;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.interactiveHighlight};
  }
`

const Button = ({ children, onClick }) => (
  <StyledButton onClick={onClick}>
    {children}
  </StyledButton>
)

Button.propTypes = {
  children: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]).isRequired,
  onClick: PropTypes.func.isRequired
}

export default Button
