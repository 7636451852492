import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const DialogTitle = ({ event, multipleEvents }) => (
  <Fragment>
    {multipleEvents && event[0].location}
    {!multipleEvents && event.name}
  </Fragment>
)

const EventShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired
})

DialogTitle.propTypes = {
  event: PropTypes.oneOfType([
    EventShape,
    PropTypes.arrayOf(EventShape)
  ]).isRequired,
  multipleEvents: PropTypes.bool
}

export default DialogTitle
